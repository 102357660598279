import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"

import Layout from "../components/layout";
import AuthorBlock from '../components/AuthorBlock';

export default function Template({data}) {
	const post = data.markdownRemark;
	const { title, author, date, featuredImage } = post.frontmatter;
	let featuredImgFluid = featuredImage.childImageSharp.fluid;
	return (
	<Layout title={title}>
		<Link to="/blog" className="back-link">Back to blogs</Link>
		<article className="single-wrapper">
			<header className="single-header">
				<Img fluid={featuredImgFluid} />
				<h1>{title}</h1>
				
				<p class="author-date">
					<span>{date}</span>
				</p>
			</header>
			<div className="post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
			<AuthorBlock author={author} />
		</article>
    </Layout>
	)
};

export const postQuery = graphql`
query BlogPost($path: String!) {
  markdownRemark(frontmatter: {path: {eq: $path}}) {
    html
    frontmatter {
		author
		date(formatString: "DD/MM/YYYY")
		path
		title
		featuredImage {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}
    }
  }
}
`;