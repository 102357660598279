import React from 'react';
import profile from "../images/profile.jpg";

const AuthorBlock = () => (
	<div className="author-block">
		<img src={profile} alt="@Bnjis"/>
		<div className="author-desc">
			<h3>Benjamin Lienart</h3>
			<p>Bonjour, Moi c'est Benji, Développeur Full stack à plein temps , passionner par le web, j'aime partager avec mes paires et apprendre tous les jours. Ce site est entièrement gratuit, si tu aime son contenu, n'hésite pas à partager et supporter ce que je fais.</p>
		</div>
	</div>
)


export default AuthorBlock;
